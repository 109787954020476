import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import environment from "environment";


export default function DeviceCategoryTraffic({
  startDate, endDate,
}) {
  const [data, setData] = useState([]);

  const colors = ["#5985FF", "#E5E5E5"];

  console.log('data :>> ', data);

  const category = {
    session: {
      count: data?.[0]?.count,
      label: `All sessions`,
      color: colors[0],
    },
    user: {
      count: data?.[1]?.count,
      label: `New users`,
      color: colors[1],
    },
  }

  const chartDataCategory = {
    datasets: [
      {
        data: [
          category?.session ? category.session.count : null,
          category?.user ? category.user.count : null,
        ],
        backgroundColor: [
          category?.session ? category.session.color : null,
          category?.user ? category.user.color : null,
        ],
      },
    ],
    labels: [
      category?.session ? category.session.label : null,
      category?.user ? category.user.label : null,
    ],
    borderWidth: 1
  };

  console.log('chartDataCategory :>> ', chartDataCategory);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch(`${environment.api}/analytics/session_details?start_date=${startDate}&end_date=${endDate}`);
      const data = await resp.json();
      setData(data?.result);
    };
    fetchData();
  }, [startDate, endDate]);

  return (
    <>{/*<h2 className="fs18 fw700 text-grayd">Sessions</h2>
      <div className="col-md-12 col-12 br10 select-week-width bg-white ms-1 pie-chart">
        <Doughnut
          data={chartDataCategory}
          options={{
            legend: { position: "left" },
          }}
        />
      </div> */}
      <div className="row mb-0 px-md-0 px-lg-2 px-0 py-4 border-0 mx-0">
        <h2 className="fs18 fw700 text-grayd">Sessions</h2>
        <div className="px-3 br4">
          <div className="row mx-0 px-0 py-3 bg-grayf">
            <div className="col-md-9 col-9">
              <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                {category.session?.label}
              </h3>
            </div>
            <div className="col-md-3 col-3 text-center">
              <span className="text-graye fs16 fw700">{category?.session?.count}</span>
            </div>
          </div>
          <div className="row mx-0 px-0 py-3 bg-grayf">
            <div className="col-md-9 col-9">
              <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                {category.user?.label}
              </h3>
            </div>
            <div className="col-md-3 col-3 text-center">
              <span className="text-graye fs16 fw700">{category?.user?.count}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}