import React from "react";
import OpenfileImage from "../../../../assets/images/openfile.svg";
import SettingsImage from "../../../../assets/images/settings.svg";
const Sidebar = ({ showMenu }) => {
  return (
    <React.Fragment>
      <div className="sidebar-container">
        <div className="sidemenu-sub p0">
          <nav id="sidebar" className="">
            <div className="">
              <ul className="list-unstyled pt40 sub-listitems px-3 mt-5">
                <li>
                  <a href="#" className="nav-link pt-3 pb-0">
                    <img src={OpenfileImage} alt="file" />
                  </a>
                </li>
                {/* <li>
                  <a href="#" className="nav-link pt-3 pb-0">
                    <img src={SettingsImage} alt="settings" />
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
