import Spinner from "react-bootstrap/Spinner";
import React, { useEffect, useState } from "react";

import Sort from "../common/sort/Sort";
import Pagination from "../common/pagination/Pagination";

import environment from "environment";

function formatLargeNumber(number) {
  if (isNaN(number)) return "";

  const suffixes = ["", "K", "M", "B", "T"];
  const numDigits = Math.floor(Math.log10(Math.abs(number)));

  if (numDigits < 3) {
    return number.toString();
  }

  const index = Math.floor(numDigits / 3);
  const suffixIndex = Math.min(index, suffixes.length - 1);
  const scaledNumber = number / Math.pow(1000, suffixIndex);
  const fixedNumber = scaledNumber.toFixed(2).replace(/\.00$/, "");

  return `${fixedNumber}${suffixes[suffixIndex]}`;
}

export default function SessionSourceTraffic({ startDate, endDate }) {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("des");
  const [sortBy, setSortBy] = useState("sessions");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSortBy("sessions");
    setSortOrder("des");
    getSessionSources();
  }, [startDate, endDate]);

  useEffect(() => {
    getSessionSources();
  }, [sortOrder, searchValue, currentPage]);

  const getSessionSources = () => {
    setIsLoading(true);

    const fetchData = async () => {
      let url = `${environment.api}/analytics/session_source?start_date=${startDate}&end_date=${endDate}&sort_by=${sortBy}&sort_order=${sortOrder}&page=${currentPage}`;

      if (searchValue) {
        url += "&search_value=" + searchValue;
      }
      const resp = await fetch(url);
      const response = await resp.json();
      setData(response?.result?.data);
      setTotalCount(response?.result?.totalCount);
    };

    fetchData().finally(() => {
      setIsLoading(false);
    });
  };

  const handleSortChange = (changeSortBy, changeSortOrder) => {
    if (sortOrder === changeSortOrder) setSortOrder("des");
    else setSortOrder(changeSortOrder);
    setSortBy(changeSortBy);
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    setSearchValue(event?.target?.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="row mb-0 px-md-0 px-lg-2 px-0 py-4 border-0 mx-0">
        <h2 className="fs18 fw700 text-grayd">
          Visits and Unique Visitors by Source / Medium
        </h2>
        <div className="px-3 br4">
          <div className="row mx-0 px-0 py-3 bg-grayf" key={-1}>
            <div className="col-md-6 col-8">
              <h3 className="fs16 fw700 text-grayd mb-0 my-2">
                <span className="d-flex">
                  <span className="pe-2">Source / Medium</span>
                  <Sort
                    sortOrder={sortOrder}
                    sortBy={sortBy === "source" ? sortBy : "none"}
                    handleSortChange={() =>
                      handleSortChange(
                        "source",
                        sortOrder === "des" ? "asc" : "des"
                      )
                    }
                  />
                </span>
                <input
                  type="text"
                  className="w-100 mt-3 p-2 no-outline border border-dark-subtle rounded"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearch}
                />
              </h3>
            </div>
            <div className="col-md-3 col-2">
              <h3 className="fs16 fw700 text-grayd mb-0 my-2">
                <span className="d-flex justify-content-center">
                  <span className="pe-2">Sessions</span>
                  <Sort
                    sortOrder={sortOrder}
                    sortBy={sortBy === "sessions" ? sortBy : "none"}
                    handleSortChange={() =>
                      handleSortChange(
                        "sessions",
                        sortOrder === "des" ? "asc" : "des"
                      )
                    }
                  />
                </span>
              </h3>
            </div>
            <div className="col-md-3 col-2">
              <h3 className="fs16 fw700 text-grayd mb-0 my-2">
                <span className="d-flex justify-content-center">
                  <span className="pe-2">Users</span>
                  <Sort
                    sortOrder={sortOrder}
                    sortBy={sortBy === "users" ? sortBy : "none"}
                    handleSortChange={() =>
                      handleSortChange(
                        "users",
                        sortOrder === "des" ? "asc" : "des"
                      )
                    }
                  />
                </span>
              </h3>
            </div>
          </div>
          {isLoading ? (
            <div className="w-full text-center my-3">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div>
              {data?.map((item, index) => (
                <div className="row mx-0 px-0 py-3 bg-grayf" key={index}>
                  <div className="col-md-6 col-8">
                    <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                      {item?.source}
                    </h3>
                  </div>
                  <div className="col-md-3 col-2 text-center">
                    <span className="text-graye fs16 fw700">
                      {formatLargeNumber(item?.sessions)}
                    </span>
                  </div>
                  <div className="col-md-3 col-2 text-center">
                    <span className="text-graye fs16 fw700">
                      {formatLargeNumber(item?.users)}
                    </span>
                  </div>
                </div>
              ))}
              {totalCount > 10 && (
                <Pagination
                  itemCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
