import React from "react";
import PropTypes from "prop-types";

import sortAsc from "../../../../assets/images/sort-asc.svg";
import sortDes from "../../../../assets/images/sort-des.svg";
import noSort from "../../../../assets/images/no-sort.svg";

export default function Sort({ handleSortChange, sortBy, sortOrder }) {
  return (
    <span>
      {sortBy === "none" ? (
        <img
          src={noSort}
          height={20}
          alt="Sort Des"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto pe-auto"
          onClick={handleSortChange}
        />
      ) : (
        <img
          src={sortOrder === "asc" ? sortAsc : sortDes}
          height={16}
          alt="Sort Des"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto pe-auto"
          onClick={handleSortChange}
        />
      )}
    </span>
  );
}

Sort.propTypes = {
  handleSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
};
