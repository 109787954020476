const baseApi = "https://analytics-api.scanflow.ai/api"; // URL of production server

const ProductionEnvironment = {
  route: {
    baseRoute: "/"
  },
  api: baseApi,
  isProduction: false,
  isDevelopment: true,
  isTesting: false
};

export default ProductionEnvironment;
