import React from "react";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function DateRangePicker({
  cardHeading, setStartDate, setEndDate
}) {
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const onChange = async (date, dateString) => {
    if (date) {
      const [start, end] = date
      const startDate = dayjs(start).format('YYYY-MM-DD')
      const endDate = dayjs(end).format('YYYY-MM-DD')
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  return (
    <>
      <div className="row mb-0 px-md-0 px-lg-2 px-0 py-4 border-0 mx-0">
        <h2 className="fs18 fw700 text-grayc col-md-4 col-lg-3 pt-1 my-1 ps-3">{cardHeading}</h2>
        <div className="col-lg-4 col-xl-5 col-md-3 col-10 text-md-end text-end pe-0 ms-xl-3">
          <RangePicker disabledDate={disabledDate} format={'DD-MMM-YYYY'}
            placement="topRight" onChange={onChange} />
        </div>
      </div>
    </>
  );
}
