import React from "react";
import RouteRegisterer from "./routes/";
import "../styles.scss";


const App = () => {
  return (
    <div>
      <RouteRegisterer />
    </div>
  );
};

export default App;
