import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import environment from "environment";


export default function DeviceCategoryTraffic({
  startDate, endDate,
}) {

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [data, setData] = useState([]);

  const colors = ["#7E22CE", "#E0C3FE", "#DBEAFE"];

  let category = data?.reduce((acc, curr, index) => {
    acc[curr?.deviceCategory] = {
      count: curr?.count,
      label: capitalizeFirstLetter(curr?.deviceCategory),
      color: colors[index],
    }

    return acc
  }, {});

  const chartDataCategory = {
    datasets: [
      {
        data: [
          category?.desktop ? category.desktop.count : null,
          category?.mobile ? category.mobile.count : null,
          category?.tablet ? category.tablet.count : null,
        ],
        backgroundColor: [
          category?.desktop ? category.desktop.color : null,
          category?.mobile ? category.mobile.color : null,
          category?.tablet ? category.tablet.color : null,
        ],
      },
    ],
    labels: [
      category?.desktop ? category.desktop.label : null,
      category?.mobile ? category.mobile.label : null,
      category?.tablet ? category.tablet.label : null,
    ],
    borderWidth: 1
  };

  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch(`${environment.api}/analytics/device_details?start_date=${startDate}&end_date=${endDate}`);
      const data = await resp.json();
      setData(data?.result);
    };
    fetchData();
  }, [startDate, endDate]);

  return (
    <>
      {/* <h2 className="fs18 fw700 text-grayd">Sessions by Device Category</h2>
      <div className="col-md-12 col-12 br10 select-week-width bg-white ms-1 pie-chart">
        <Doughnut
          data={chartDataCategory}
          options={{
            legend: { position: "left" },
          }}
        />
      </div>
      <div className="row m-auto">
        <div className="col-md-12 col-12 bg-white text-center ms-0 ms-md-0 pe-md-0 pl-6rem pe-0">
          <ul className="list-group list-group-horizontal-md">
            <li className="list-group-item border-0 w-25 px-0 text-md-end text-start py-0">
              <div className="d-inline-flex"><p className="dot-circle mt-1"></p>
                <p className="ms-2 fs16 fw700 text-grayc">Desktop</p></div>
            </li>
            <li className="list-group-item border-0 w-auto px-0 text-start ms-0 ms-md-3 py-0">
              <div className="d-inline-flex">
                <p className="dot-circle dot-purple mt-1"></p>
                <p className="ms-2 fs16 fw700 text-grayc">
                  Mobile</p>
              </div>
            </li>
            <li className="list-group-item border-0 w-25 px-0 text-start ms-0 ms-md-3 py-0">
              <div className="d-inline-flex">
                <p className="dot-circle dot-blue mt-1">
                </p>
                <p className="ms-2 fs16 fw700 text-grayc">Tablet</p>
              </div>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="row mb-0 px-md-0 px-lg-2 px-0 py-4 border-0 mx-0">
        <h2 className="fs18 fw700 text-grayd">Sessions by Device Category</h2>
        <div className="px-3 br4">
          <div className="row mx-0 px-0 py-3 bg-grayf">
            <div className="col-md-9 col-9">
              <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                {category?.desktop?.label}
              </h3>
            </div>
            <div className="col-md-3 col-3 text-center">
              <span className="text-graye fs16 fw700">{category?.desktop?.count}</span>
            </div>
          </div>
          <div className="row mx-0 px-0 py-3 bg-grayf">
            <div className="col-md-9 col-9">
              <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                {category?.mobile?.label}
              </h3>
            </div>
            <div className="col-md-3 col-3 text-center">
              <span className="text-graye fs16 fw700">{category?.mobile?.count}</span>
            </div>
          </div>
          <div className="row mx-0 px-0 py-3 bg-grayf">
            <div className="col-md-9 col-9">
              <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                {category?.tablet?.label}
              </h3>
            </div>
            <div className="col-md-3 col-3 text-center">
              <span className="text-graye fs16 fw700">{category?.tablet?.count}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
