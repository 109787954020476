import React from "react";
import logo from "../../../../assets/images/logo.svg";
import profile from "../../../../assets/images/user.png";
import bell from "../../../../assets/images/bell.svg";
import stop from "../../../../assets/images/stop.svg";
import { ButtonOutline } from "../button/Button";

const Header = (props) => {
  return (
    <div className="container-fluid header-container">
      <header className="d-flex flex-wrap justify-content-center header-content mb-0">
        <img src={logo} alt="logo" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto w150" />
        {/* <ul className="nav nav-pills">
          <li className="nav-item">
            <ButtonOutline
              label="Share via email"
              handleClick={() => { }} btnOutlineStyle="me-4" />
          </li>
          <li className="nav-item"><img src={profile} alt="profile" className="profile-image" />
            <span className="dot-green"> </span>
          </li>
          <li className="nav-item ms-3">
            <div><span className="text-capitalize user-name">User</span></div>
            <div><span className="text-capitalize user-designation">Designation</span></div>
          </li>
          <li className="nav-item ms-2 ms-md-4 mt-2"><img src={bell} alt="bell" /></li>
          <li className="nav-item ms-2 ms-md-4 mt-2"><img src={stop} alt="stop" /></li>
        </ul> */}
      </header>
    </div>
  )
};

export default Header;
