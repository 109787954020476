import React from "react";

function Pagination({ itemCount, currentPage, onPageChange }) {
  const totalPages = Math.ceil(itemCount / 10);

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="w-full d-flex justify-content-between bg-grayf mt-2 p-2">
      <button
        className="btn btn-outline-secondary text-grayc"
        onClick={handlePreviousClick}
        disabled={currentPage === 1}
      >
        Prev
      </button>
      <p className="fs14 fw700 text-grayc mb-0 my-2">
        Page {currentPage} of {totalPages}
      </p>
      <button
        className="btn btn-outline-secondary text-grayc"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
}

export default Pagination;
