import Header from "./Header";
import Sidebar from "./Sidebar";
import Dashboard from "views/pages/Dashboard";

const Sitelayout = () =>{
return(
<div className="web-layout">
    <Header/>
    <aside className="own-sidemenu-main">
        <Sidebar/>
    </aside>
    <section className="page-content mt-0 py-4">
        <Dashboard/>
    </section>
</div>
)}
export default Sitelayout;