import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";

import React, { useState } from "react";

import { ButtonOutline } from "../components/common/button/Button";
import DateRangePicker from "../components/calendar/DateRangePicker";
import CountryTraffic from "../components/country/CountryTraffic";
import SessionDonut from "../components/session/SessionDonut";
import KeywordTraffic from "../components/keyword/KeywordTraffic";
import SessionSourceTraffic from "../components/sourceMedium/SourceMediumTraffic";
import DeviceCategoryTraffic from "../components/device/DeviceCategoryTraffic";
import LandingPageTraffic from "../components/landingPage/LandingPageTraffic";
import BrowserTraffic from "views/components/browser/BrowserTraffic";
import SocialNetworkTraffic from "views/components/SocialNetwork/SocialNetworkTraffic";

const Dashboard = () => {
  const [startDateWeek1, setStartDateWeek1] = useState("");
  const [endDateWeek1, setEndDateWeek1] = useState("");

  const [startDateWeek2, setStartDateWeek2] = useState("");
  const [endDateWeek2, setEndDateWeek2] = useState("");

  const downloadPdf = async () => {
    const input_1 = document.getElementById("comparison_1");
    const imageData1 = await htmlToImage.toPng(input_1, { quality: 1 });

    const input_2 = document.getElementById("comparison_2");
    const imageData2 = await htmlToImage.toPng(input_2, { quality: 1 });

    const input_3 = document.getElementById("comparison_3");
    const imageData3 = await htmlToImage.toPng(input_3, { quality: 1 });

    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imageData1);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imageData1, "PNG", 3, 0, pdfWidth, pdfHeight, "", "FAST");

    pdf.addPage();
    const imgProps2 = pdf.getImageProperties(imageData2);
    const pdfWidth2 = pdf.internal.pageSize.getWidth();
    const pdfHeight2 = (imgProps2.height * pdfWidth2) / imgProps2.width;
    pdf.addImage(imageData2, "PNG", 3, 0, pdfWidth2, pdfHeight2, "", "FAST");

    pdf.addPage();
    const imgProps3 = pdf.getImageProperties(imageData3);
    const pdfWidth3 = pdf.internal.pageSize.getWidth();
    const pdfHeight3 = (imgProps3.height * pdfWidth3) / imgProps3.width;
    pdf.addImage(imageData3, "PNG", 3, 0, pdfWidth3, pdfHeight3, "", "FAST");

    pdf.save("download.pdf");
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-8">
          <h1 className="page-heading">Comparison</h1>
        </div>
        <div className="col-md-4 text-md-end text-start">
          <ButtonOutline
            btnOutlineStyle="btn-blue px-5 py-2 me-0"
            label="Export PDF"
            handleClick={downloadPdf}
          />
        </div>
      </div>
      <div id="comparison_1">
        <div className="row g-0 my-2 mx-0">
          <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1">
            <DateRangePicker
              key={1}
              cardHeading="Select Week 1"
              setStartDate={setStartDateWeek1}
              setEndDate={setEndDateWeek1}
            />
          </div>
          <div
            className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2"
          >
            <DateRangePicker
              key={2}
              cardHeading="Select Week 2"
              setStartDate={setStartDateWeek2}
              setEndDate={setEndDateWeek2}
            />
          </div>
        </div>

        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1 p-4">
              <SessionDonut
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-4"
            >
              <SessionDonut
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1">
              <SocialNetworkTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-0"
            >
              <SocialNetworkTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={2}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1">
              <LandingPageTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-0"
            >
              <LandingPageTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={2}
              />
            </div>
          ) : (
            ""
          )}
        </div>



        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1 p-4">
              <DeviceCategoryTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-4"
            >
              <DeviceCategoryTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div id="comparison_2">
        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1">
              <CountryTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-0"
            >
              <CountryTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={2}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1">
              <BrowserTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-0"
            >
              <BrowserTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={2}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1 p-4">
              <SessionSourceTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-4"
            >
              <SessionSourceTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={2}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div id="comparison_3">
        <div className="row g-0 my-4 mx-0">
          {startDateWeek1 && endDateWeek1 ? (
            <div className="col-md-6 col-12 br10 select-week-width bg-white ms-1 p-4">
              <KeywordTraffic
                startDate={startDateWeek1}
                endDate={endDateWeek1}
                key={1}
              />
            </div>
          ) : (
            ""
          )}
          {startDateWeek2 && endDateWeek2 ? (
            <div
              className="col-md-6 col-12 br10 select-week-width bg-white end-date-picker 
        mt-lg-0 mt-md-2 mt-2 p-4"
            >
              <KeywordTraffic
                startDate={startDateWeek2}
                endDate={endDateWeek2}
                key={2}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
