import React, { useEffect, useState } from "react";
import environment from "environment";

export default function LandingPageTraffic({
  startDate, endDate,
}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch(`${environment.api}/analytics/landing_page_details?start_date=${startDate}&end_date=${endDate}`);
      const data = await resp.json();
      setData(data?.result);
    };
    fetchData();
  }, [startDate, endDate]);

  return (
    <>
      <div className="row mb-0 px-md-0 px-lg-2 px-0 py-4 border-0 mx-0">
        <h2 className="fs18 fw700 text-grayd">Landing Page</h2>
        <div className="px-3 br4">
          <div className="row mx-0 px-0 py-3 bg-grayf" key={-1}>
            <div className="col-md-9 col-9">
              <h3 className="fs16 fw700 text-grayd mb-0 my-2">
                Landing page
              </h3>
            </div>
            <div className="col-md-3 col-3 text-center">
              <span className="text-grayd fs16 fw700">Sessions</span>
            </div>
          </div>
          {data?.map((item, index) => (
            <div className="row mx-0 px-0 py-3 bg-grayf" key={index}>
              <div className="col-md-9 col-9">
                <h3 className="fs16 fw700 text-grayc mb-0 my-2">
                  {item?.landingPage}
                </h3>
              </div>
              <div className="col-md-3 col-3 text-center">
                <span className="text-graye fs16 fw700">{item?.count}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

